/** @format */

import {useRouter} from 'next/router';
import {useEffect, useCallback, useReducer, useRef} from 'react';
import {sortBy} from 'lodash';
import axios from 'axios';

import styles from '@styles/common/Banner.module.scss';
import {getRouteUrl, categorySlug} from '@utils/functions';
import {getBannerLocation, bannerLocation} from '@utils/showBannerLocation';
import {useApp} from '@context/AppProvider';
import {api} from '@utils/api';
import {init, reducer} from '@reducers/bannerSlider';

import Banner from './Banner.component';
import Slider from './Slider.component';

function BannerSlider(props) {
	const {types} = props;
	const router = useRouter();
	const req = useRef(null);
	let pageUrl = getRouteUrl(router.asPath);

	const {
		idb: {session},
	} = useApp();

	const [state, dispatch] = useReducer(reducer, init);

	const resetBannersData = useCallback(() => {
		dispatch({
			type: 'set_bannerSlider',
			data: {banners: [], types: null, routeUrl: '', loading: true},
		});
	}, []);
	const fetchBannerSlider = useCallback(
		async url => {
			if (session) {
				if (req.current) {
					req.current.cancel();
				}
				req.current = axios.CancelToken.source();
				if (bannerLocation.indexOf(url) > -1) {
					const checkUrl = router.asPath.split('/')[1];
					if (checkUrl !== state.routeUrl) {
						resetBannersData();
					}
					let location = await getBannerLocation(getRouteUrl(url));
					let slug = await categorySlug(router.query, router.asPath);
					const obj = {
						banners: [],
						types: null,
						loading: false,
						routeUrl: router.asPath.split('/')[1],
					};

					let device = null;
					let windowWidth = document.documentElement.clientWidth;
					if (windowWidth <= 767) {
						device = 2;
					} else if (windowWidth > 767 && windowWidth <= 1024) {
						device = 3;
					} else if (windowWidth > 1024 && windowWidth <= 1367) {
						device = 4;
					} else {
						device = 1;
					}
					const {banner, cancelled} = await api(
						{
							url: `/banner`,
							method: 'POST',
							cancelToken: req.current.token,
							data: {
								location: location,
								device: device,
								categoryDrop: slug ? slug : '',
							},
						},
						session,
					);
					if (cancelled) {
						return false;
					}
					if (banner && banner.length > 0) {
						if (banner && banner[0].type === 0) {
							obj.types = 'banner';
							obj.banners = banner;

							if (
								state.banners &&
								state.banners.length &&
								state.banners[0]._id === obj.banners[0]._id
							)
								delete obj['banners'];
							setBannerSliderData(obj, checkUrl);
						} else if (banner && banner[0].type === 1) {
							obj.types = 'slider';
							if (banner[0].images.length > 0) {
								let sliderArray = sortBy(banner[0].images, o => o.image_order);
								obj.banners = sliderArray ? sliderArray : [];
							}
							if (
								state.banners &&
								state.banners.length &&
								state.banners[0]._id === obj.banners[0]._id
							)
								delete obj['banners'];
							setBannerSliderData(obj, checkUrl);
						} else {
							resetBannersData();
						}
					}
				} else {
					resetBannersData();
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[resetBannersData, router.asPath, router.query, session],
	);
	const setBannerSliderData = (obj, checkUrl) => {
		dispatch({
			type: 'set_bannerSlider',
			data: {pageUrl: checkUrl, ...obj},
		});
	};
	useEffect(() => {
		fetchBannerSlider(getRouteUrl(router.asPath));
		return () => {
			if (req.current) {
				req.current.cancel();
				req.current = null;
			}
		};
	}, [session, fetchBannerSlider, router.asPath]);

	if (types === 'header' && pageUrl === 'all-stores') {
		return null;
	} else {
		return state.banners.length && !state.loading ? (
			<>
				{state.types === 'slider' && (
					<Slider
						styles={styles}
						url={getRouteUrl(router.asPath)}
						sliderData={state.banners}
					></Slider>
				)}
				{state.types === 'banner' && (
					<Banner
						styles={styles}
						url={getRouteUrl(router.asPath)}
						sliderData={state.banners}
					></Banner>
				)}
			</>
		) : (
			<></>
		);
	}
}
export default BannerSlider;
