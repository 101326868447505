import Link from 'next/link';
import classnames from 'classnames';

import LoadImage from './LoadImage.component';

function Banner(props) {
	const {styles, sliderData, url} = props;
	return (
		<>
			{!!(
				sliderData.length > 0 &&
				sliderData[0].images.length &&
				url !== 'all-stores'
			) && (
				<div className={styles.banner}>
					{sliderData[0].banner_href ? (
						<>
							<div className={styles.linkType}>
								<Link
									href={sliderData[0].banner_href}
									shallow={true}
									passHref={true}
								>
									<a>
										<LoadImage
											bannerImage={styles.bannerImage}
											image={styles.image}
											url={sliderData[0].images[0].image_url}
											alt={sliderData[0].title}
										/>
									</a>
								</Link>
							</div>
						</>
					) : (
						<LoadImage
							bannerImage={styles.bannerImage}
							image={styles.image}
							url={sliderData[0].images[0].image_url}
							alt={sliderData[0].title}
						/>
					)}
				</div>
			)}
			{!!(
				sliderData.length > 0 &&
				sliderData[0].images.length &&
				url === 'all-stores'
			) && (
				<div className={classnames('container', styles.banner)}>
					<div className='row'>
						<div className='col-sm-12'>
							{sliderData[0].banner_href ? (
								<div className={styles.linkType}>
									<Link
										href={sliderData[0].banner_href}
										shallow={true}
										passHref={true}
									>
										<a>
											<LoadImage
												bannerImage={styles.bannerImage}
												image={styles.image}
												url={sliderData[0].images[0].image_url}
												alt={sliderData[0].title}
											/>
										</a>
									</Link>
								</div>
							) : (
								<LoadImage
									bannerImage={styles.bannerImage}
									image={styles.image}
									url={sliderData[0].images[0].image_url}
									alt={sliderData[0].title}
								/>
							)}
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Banner;
