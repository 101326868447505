import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css';
import 'swiper/css/effect-fade';
import Link from 'next/link';
import SwiperCore, {Autoplay, Pagination, Navigation, EffectFade} from 'swiper';

import LoadImage from './LoadImage.component';

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);

function Slider(props) {
	const {styles, sliderData} = props;

	return (
		<>
			{sliderData && (
				<Swiper
					spaceBetween={0}
					centeredSlides={true}
					simulateTouch={false}
					autoplay={{
						delay: 10000,
						disableOnInteraction: false,
					}}
					pagination={{
						clickable: true,
					}}
					observer={true}
					observeParents={true}
					navigation={true}
					allowTouchMove={true}
					slidesPerView={1}
					speed={3000}
					modules={[EffectFade]}
					effect='slide'
					className={styles.banner}
				>
					{sliderData.map((image, key) => (
						<SwiperSlide key={key} className={styles.bannerSlider}>
							{image.href ? (
								<>
									<div className={styles.linkType}>
										<Link href={image.href} shallow={true} passHref={true}>
											<a>
												<LoadImage
													bannerImage={styles.bannerImage}
													image={styles.image}
													url={image.image_url}
													alt={image._id}
												/>
											</a>
										</Link>
									</div>
								</>
							) : (
								<LoadImage
									bannerImage={styles.bannerImage}
									image={styles.image}
									url={image.image_url}
									alt={image._id}
								/>
							)}
						</SwiperSlide>
					))}
				</Swiper>
			)}
		</>
	);
}

export default Slider;
