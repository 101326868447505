/** @format */
export const init = {
	banners: [],
	types: null,
	loading: true,
	routeUrl: '',
};

export const reducer = (state, action) => {
	switch (action.type) {
		case 'set_bannerSlider':
			return {
				...state,
				...action.data,
			};
		default:
			return state;
	}
};
