export const bannerLocation = [
	'category',
	'shop-all',
	'Individual',
	'brands',
	'brand',
	'stores',
	'store',
	'product',
	'home-page',
	'drop-all-auction',
	'drop-auctions',
	'all-stores',
];

export const getBannerLocation = async path => {
	let pageLocation = '';
	switch (path) {
		case 'category':
			pageLocation = 12;
			break;
		case 'shop-all':
			pageLocation = 1;
			break;
		case 'all-stores':
			pageLocation = 3;
			break;
		case 'Individual':
			pageLocation = 1;
			break;
		case 'brands':
			pageLocation = 4;
			break;
		case 'brand':
			pageLocation = 1;
			break;
		case 'stores':
			pageLocation = 6;
			break;
		case 'store':
			pageLocation = 7;
			break;
		case 'product':
			pageLocation = 2;
			break;
		case 'home-page':
			pageLocation = 9;
			break;
		case 'drop-all-auction':
			pageLocation = 10;
			break;
		case 'drop-auctions':
			pageLocation = 8;
			break;
	}
	return pageLocation;
};
