import Image from 'next/image';

function LoadImage(props) {
	return (
		<div className={props.bannerImage}>
			<Image
				layout='fill'
				className={props.image}
				src={props.url}
				alt={props.alt}
				quality={100}
				placeholder='blur'
				blurDataURL='/assets/images/blur.jpg'
				priority={true}
			/>
		</div>
	);
}
export default LoadImage;
